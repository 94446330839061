import React from "react";
import { connect } from "react-redux";
import TopBarProgress from "react-topbar-progress-indicator";

class Spinner extends React.Component {
    constructor(props) {
        TopBarProgress.config({
            barThickness: 5,
            barColors: {
                '0': 'rgba(26,  188, 156, .7)',
                '.3': 'rgba(41,  128, 185, .7)',
                '1.0': 'rgba(231, 76,  60,  .7)'
            },
            shadowBlur: 5,
            shadowColor: 'rgba(0, 0, 0, .5)',
        });

        super(props);
        this.state = {};
    }

    render() {
        return (
            // <div>{this.props.app.loading === true && <TopBarProgress />}</div>
            <></>
        );
    }
}

const mapStateToProps = (state) => ({
    app: state.entities.app
});

export default connect(mapStateToProps, null)(Spinner);