import React, { lazy } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { Routes } from "../common/routing";

const IdpSolution = lazy(() => import("../screens/Website/Content/Marketing/IDP/Index"));


const PortalRouter = (props) => {
  return (
    <Switch>
      <Route exact path={Routes.Website.Pages.Conversion.Content.Solutions.IDP}>
        <IdpSolution />
      </Route>
    </Switch>
  );
};

export default withRouter(PortalRouter);
