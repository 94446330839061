import React from "react";
import { connect } from "react-redux";
import { isEmpty, isFull } from "../../common/utils";
import { logout } from "../../store/reducers/accountService";
import Cookies from 'js-cookie';
import { Auth } from "../../common/constants";
import { decodeJwt, isJwtValid } from "../../common/identity/authUtils";

export default function WithAuth(WrappedComponent, selectData) {
  // ...and returns another component...
  class WithAuthHoC extends React.Component {

    getSecondarySocialAuthTokenInTransitionTimes = () => {
      let cookies = Cookies.get(Auth.TokenHolderCookieName);
      let userObject = undefined;

      if (cookies) {
        userObject = JSON.parse(cookies);
      }

      return userObject?.user;
    }

    getUser = () => {
      if (this.isAuthenticationValid(this.props.user)) {
        //Add claims to the user
        let augmentedUser = {
          ...this.props.user,
          claims: decodeJwt(this.props.user.accessToken)
        }

        return augmentedUser;
      }
      return undefined;
    }

    getClaim = (claim) => {
      if (isEmpty(claim)) {
        return undefined;
      }

      let userHolder = this.getUser();
      return userHolder?.claims[claim];
    }

    canPerform = (action) => {
      let user = this.getUser();
      //To be implemented
      return true;
    }

    isAuthenticationValid = (userInput) => {
      if (isFull(userInput)) {
        let user = userInput;
        if (isJwtValid(user?.accessToken)) {
          return true;
        }
      } else {
        let user = this.getSecondarySocialAuthTokenInTransitionTimes();
        if (isJwtValid(user?.accessToken)) {
          return true;
        }
      }
      return undefined;
    }

    render() {
      return (
        <WrappedComponent
          isAuthenticated={this.isAuthenticationValid(this.props.user)}
          user={this.getUser()}
          canUserPerform={this.canPerform}
          getClaim={this.getClaim}
          logout={() =>
            this.props.logout({
              accessToken: this.getUser()?.accessToken,
              refreshToken: this.getUser()?.refreshToken,
            })
          }
          isAuthenticationValid={this.isAuthenticationValid}
          accessToken={this.getUser()?.accessToken}
          refreshToken={this.getUser()?.refreshToken}
          {...this.props}
        />
      );
    }
  }

  const mapStateToProps = (state) => ({
    user: state.entities.accounts.membership.user,
  });

  const mapDispatchToProps = (dispatch) => ({
    logout: ({ accessToken, refreshToken }) =>
      dispatch(logout({ accessToken, refreshToken })),
  });

  return connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(WithAuthHoC);
}
