import * as React from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom-v5-compat";
import { getEnvironmentVariables } from "../../../common/utils";
import { useState } from "react";
import { Routes } from "../../../common/routing";
import WithOrganisations from "../../../components/Organisations/WithOrganisations";

const MarketingNavBar = (props) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const navigation = [
    { name: "Prices", href: Routes.Website.Pages.Conversion.Prices },
    // { name: "Docs", href: Routes.Website.Pages.Docs.Base },
    { name: "Blogs", href: Routes.Website.Pages.Blogs.Directory },
    {
      name: "Ask an expert",
      href: Routes.Website.Pages.Conversion.TalkToExpert,
    },
  ];

  return (
    <div className="">
      {/* Header */}
      <header className="relative bg-gray-900 inset-x-0 top-0 z-50">
        <nav
          className="flex items-center justify-between p-6 lg:px-8"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <a
              href={`${getEnvironmentVariables().REACT_APP_WEBSITE_PUBLIC_URL}`}
              className="-m-1.5 p-1.5"
            >
              <span className="sr-only">Pellerex Pty. Ltd.</span>
              <img
                className="h-8 w-auto"
                src="https://cdn.pellerex.com/public/ecosystem/web/home/pellerex-logo.png"
                alt=""
              />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-lg font-semibold  leading-8 text-white hover:text-gray-300 transition-colors duration-200 ease-out"
              >
                {item.name}
              </a>
            ))}
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            {!props.isAuthenticated && <Link
              to={`${getEnvironmentVariables().REACT_APP_APPLICATION_PUBLIC_URL
                }${Routes.Application.Identity.Login}`}
              className="text-lg font-semibold leading-6 text-gray-900 pb-2.5 pt-2 px-4 rounded-3xl bg-white hover:text-gray-400 transition-colors duration-200 ease-out"
            >
              Log in <span aria-hidden="true">&gt;</span>
            </Link>}
            {props.isAuthenticated && <Link
              to={`${getEnvironmentVariables().REACT_APP_APPLICATION_PUBLIC_URL
                }${Routes.Application.Identity.Organisation.Selection}`}
              className="text-lg font-semibold leading-6 text-gray-900 pb-2.5 pt-2 px-4 rounded-3xl bg-white hover:text-gray-400 transition-colors duration-200 ease-out"
            >
              Dashboard <span aria-hidden="true">&gt;</span>
            </Link>}

          </div>
        </nav>
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Pellerex Pty. Ltd.</span>
                <img
                  className="h-8 w-auto"
                  src="https://cdn.pellerex.com/public/ecosystem/web/home/pellerex-logo.png"
                  alt=""
                />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="py-6">
                  <a
                    href={`${getEnvironmentVariables().REACT_APP_APPLICATION_PUBLIC_URL}${Routes.Application.Identity.Login}`}
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Log in
                  </a>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
    </div>
  );
};

export default WithOrganisations(MarketingNavBar);