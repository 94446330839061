import logService from '../../../services/monitoring/logService';
import {
    uiNotificationSent
} from '../actionUtils';

const logger = (param) => (store) => (next) => (action) => {
    if (action.type === uiNotificationSent.type) {
        let errorMessage = `An error occured. 
            ErrorCode: "${action.payload?.errorCode}". 
            Message: "${action.payload?.message}".
            StatusCode: "${action.payload?.statusCode}".
            MessageShownToUser: "${action.payload?.userFriendlyMessage}"`;

        if (!action.payload.success) {
            logService.error(new Error(errorMessage))
        }
    }
    return next(action);
};

export default logger;