import { Fragment, useState } from "react";
import { Transition } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { useEffect } from "react";
import WithNotification from "./WithNotification";
import { General } from "../../common/constants";

const PellerexNotification = (props) => {
  const [show, setShow] = useState(false);

  const closeNotification = ({ id }) => {
    props.deNotify({
      id,
    });
  };

  useEffect(() => {
    setShow(props.visible);
  }, [props.visible, props.message]);

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      {show && (
        <div
          aria-live="assertive"
          className="flex items-end pointer-events-none m-2 w-100 sm:items-start sm:m-2"
        >
          <div className="flex w-full flex-col items-center sm:items-end">
            {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
            <Transition
              show={show}
              as={Fragment}
              enter="transform ease-out duration-300 transition"
              enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
              enterTo="translate-y-0 opacity-100 sm:translate-x-0"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 ">
                <div className="p-4">
                  <div className="flex items-start">
                    <div className="flex-shrink-0 mt-1">
                      {props.type === General.Notification.Types.Success && (
                        <CheckCircleIcon
                          className="h-10 w-10 text-green-600"
                          aria-hidden="true"
                        />
                      )}
                      {props.type === General.Notification.Types.Error && (
                        <XMarkIcon
                          className="h-10 w-10 text-red-600"
                          aria-hidden="true"
                        />
                      )}
                      {props.type === General.Notification.Types.Warning && (
                        <ExclamationTriangleIcon
                          className="h-10 w-10 bg-yellow-600"
                          aria-hidden="true"
                        />
                      )}
                    </div>
                    <div className="ml-1 w-0 flex-1 pt-0.5">
                      <p className="text-base font-medium text-gray-900">
                        {props.title}
                      </p>
                      <p className="mt-1 text-base text-gray-500">
                        {props.message}
                      </p>
                    </div>
                    <div className="ml-4 flex flex-shrink-0 ">
                      <button
                        type="button"
                        className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 "
                        onClick={() => {
                          closeNotification({ id: props.id });
                        }}
                      >
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-5 w-5 " aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
          </div>
        </div>
      )}
    </>
  );
};

export default WithNotification(PellerexNotification);
