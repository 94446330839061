import { routeChangeBegan } from '../actionUtils';
import history from "../../../common/history";
import { Routes } from '../../../common/constants';

const routeManager = (param) => (store) => (next) => (action) => {
    if (action.type !== routeChangeBegan.type) {
        return next(action);
    }
    const { intent, route, parameters } = action.payload;
    switch (intent) {
        case 'logout':
            history.push('/');
            break;
        case 'login':
            break;
        case 'dashboard':
            history.push(`${Routes.Marketplace.Pages.Landings.Dashboard}/${parameters.appSpaceId}?productId=${parameters.productId}`);
            break;
        case 'external-payment-authentication':
            window.location = route
            break;
        default:
            break;
    }

    next(action);
};

export default routeManager;