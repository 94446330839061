import * as React from 'react';
import TalkToExperts from './TalkToExperts';
import styles from './Index.module.scss';
const Index = (props) => {
    return (
        <div>
            <TalkToExperts />
        </div>
    );
};

export default Index;