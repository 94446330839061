// @flow
import * as React from "react";
import PellerexNotification from "./PellerexNotification";
import WithNotification from "./WithNotification";

const PellerexNotifications = (props) => {
  return (
    <div className="fixed top-16 right-0 z-[10000]">
      {props.notifications?.map((notification) => (
        <PellerexNotification
          id={notification.id}
          visible={true}
          {...notification}
        />
      ))}
    </div>
  );
};

export default WithNotification(PellerexNotifications);
