import {
    General
} from '../../../common/constants';
import {
    getRandomId,
    isFull
} from '../../../common/utils';
import {
    setNotifications
} from '../../reducers/appService';
import {
    uiNotificationSent
} from '../actionUtils';

const toaster = (params) => ({
    dispatch,
    getState
}) => (next) => (action) => {

    if (action.type === uiNotificationSent.type) {
        let updatedNotifications = [...getState().entities.app.notifications];
        updatedNotifications.push({
            id: getRandomId(),
            title: getMessageTitle(action.payload),
            message: action.payload.userFriendlyMessage,
            type: getMessageType(action.payload)
        });

        dispatch(setNotifications(updatedNotifications));

    }
    return next(action);
};

const getMessageTitle = (message) => {
    switch (message.errorCode) {
        case General.ErrorCodes.Identity.InvalidCredentials:
            return 'Invalid credentials';

        default:
            break;
    }
}

const getMessageType = (message) => {
    switch (message.errorCode) {
        case General.ErrorCodes.Identity.InvalidCredentials:
            return General.Notification.Types.Error;

        default:
            break;
    }

    if (isFull(message.errorCode)) {
        return General.Notification.Types.Error;
    }
}

export default toaster;