import {
    combineReducers
} from "redux";
import accountReducer from "./reducers/accountService";
import appReducer from "./reducers/appService";
import organisationReducer from "./reducers/organisationService";

export default combineReducers({
    accounts: accountReducer,
    app: appReducer,
    organisations: organisationReducer
});