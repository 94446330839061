import { createRoot } from 'react-dom/client';
import * as serviceWorker from "./serviceWorker";
import './index.css';
import App from "./App";

const container = document.getElementById('root');
const root = createRoot(container); // Create a root.
root.render(<App />); // Render your application.

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();