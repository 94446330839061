import {
    applyMiddleware,
    createStore,
    getDefaultMiddleware
} from "@reduxjs/toolkit";

import rootReducer from "./rootReducer";

import logger from "./middleware/core/logger";

import api from "./middleware/core/api";

import {
    composeWithDevTools
} from 'redux-devtools-extension'

// import storage from 'redux-persist/lib/storage' // add /session at the end to go back to session storage mode
import Cookies from 'js-cookie';
import {
    CookieStorage
} from 'redux-persist-cookie-storage'

import {
    getEnvironmentVariables
} from '../common/utils';

import {
    persistStore,
    persistReducer
} from 'redux-persist'

import routeManager from "./middleware/core/routeManager";
import toaster from "./middleware/core/toaster";

//How to inject an orchestrator in the middleware
import {
    sampleOrchestrator,
} from "./middleware/apiOrchestrators/sampleOrchestrator";

// getDefaultMiddleware automatically registers thunk, the middleware used to dispach functions
let initialState = {};

const persistConfig = {
    key: 'root',
    storage: new CookieStorage(Cookies, {
        setCookieOptions: {
            domain: getEnvironmentVariables().REACT_APP_HOST,
        }
    })
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware = applyMiddleware(
    //Where to inject an orchestrator into the middleware
    // sampleOrchestrator,
    ...getDefaultMiddleware({
        serializableCheck: false
    }),
    logger({
        destination: "console"
    }),
    toaster(),
    api,
    routeManager());

const devTools =
    getEnvironmentVariables().NODE_ENV === "production" ?
    middleware :
    composeWithDevTools(middleware);

export default () => {
    let store = createStore(
        persistedReducer,
        initialState,
        devTools
    );

    let persistor = persistStore(store, {})
    return {
        store,
        persistor
    }
}