import {
    createSlice
} from "@reduxjs/toolkit";

export const setActiveOrganisationId = (activeOrganisationId) => {
    return {
        type: activeOrganisationIdSet.type,
        payload: {
            activeOrganisationId
        }
    };
};

export const setActiveAppSpaceId = (activeAppSpaceId) => {
    return {
        type: activeAppSpaceIdSet.type,
        payload: {
            activeAppSpaceId
        }
    };
};

//
//Store Slice Creator
//

const slice = createSlice({
    name: "organisationReducer",
    initialState: {},
    reducers: {
        activeAppSpaceIdSet: (organisations, action) => {
            organisations = {
                ...organisations,
                activeAppSpaceId: action.payload.activeAppSpaceId
            };
            return organisations;
        },
        activeOrganisationIdSet: (organisations, action) => {
            organisations = {
                ...organisations,
                activeOrganisationId: action.payload.activeOrganisationId
            };
            return organisations;
        }
    }
});

export const {
    activeOrganisationIdSet,
    activeAppSpaceIdSet
} = slice.actions;

export default slice.reducer;