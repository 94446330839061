import {
    isEmpty,
    isFull
} from "./utils";

const StorageKeys = {
    LocalStorage: 'Pellerex',
    SessionStorage: 'Pellerex'
}

export const retrieveSessionStorage = (key) => {
    let retriviedSessionStorage = sessionStorage.getItem(StorageKeys.SessionStorage);

    var obj = JSON.parse(retriviedSessionStorage);

    if (isEmpty(key)) {
        return undefined;
    }

    if (isFull(obj)) {
        return obj[key]
    }
    return undefined;
}

export const saveSessionStorage = (key, value) => {
    let jsonStorage = retrieveSessionStorage();

    if (isEmpty(jsonStorage)) {
        jsonStorage = {};
    }

    jsonStorage[key] = value;

    sessionStorage.setItem(StorageKeys.SessionStorage, JSON.stringify(jsonStorage));
}