import React from "react";
import { connect } from "react-redux";
import { getEnvironmentVariables, isFull } from '../../common/utils';
import WithAuth from "../Auth/WithAuth";
import { isEmpty } from "lodash";
import { retrieveSessionStorage } from "../../common/storageUtils";
import { Application } from "../../common/constants";
import {
    setActiveAppSpaceId
} from '../../store/reducers/organisationService';

export default function WithOrganisations(WrappedComponent, selectData) {
    class WithOrganisationsHoC extends React.Component {
        getActiveOrganisation = () => {
            let allMyOrgs = retrieveSessionStorage(Application.StorageKeys.MyOrganisations);
            if (isEmpty(allMyOrgs)) {
                return undefined;
            }
            let activeOrg = allMyOrgs.filter(o => o.id === this.props.organisations?.activeOrganisationId)[0];
            return activeOrg;
        }

        getActiveAppSpace = () => {
            let allMyOrgs = retrieveSessionStorage(Application.StorageKeys.MyOrganisations);

            if (isEmpty(allMyOrgs)) {
                return undefined;
            }
            let activeOrg = allMyOrgs?.filter(o => o.id === this.props.organisations?.activeOrganisationId)[0];
            let appSpace = activeOrg?.appSpaces?.filter(appSpace => appSpace.id === this.props.organisations?.activeAppSpaceId)[0]
            return appSpace;
        }

        getActiveTenantId = () => {
            return getEnvironmentVariables().REACT_APP_PELLEREX_TENANT_ID;
        }

        setActiveAppSpaceId = (appSpaceId) => {
            props.setActiveAppSpaceId(appSpaceId);
        }

        ///
        ///To refresh the dashboard with the latest assets created in the AppSpace
        ///
        getOrganisations = () => {
            let allMyOrgs = retrieveSessionStorage(Application.StorageKeys.MyOrganisations);
            return allMyOrgs;
        };

        render() {
            return <WrappedComponent
                activeOrganisation={this.getActiveOrganisation()}
                activeOrganisationId={this.props.organisations?.activeOrganisationId}
                activeAppSpace={this.getActiveAppSpace()}
                activeAppSpaceId={this.props.organisations?.activeAppSpaceId}
                activeTenantId={this.getActiveTenantId()}
                isOrganisationSelected={isFull(this.props.organisations?.activeOrganisationId)}
                isAppSpaceSelected={isFull(this.props.organisations?.activeAppSpaceId)}
                organisations={this.getOrganisations()}
                organisationList={this.getOrganisations()}
                {...this.props} />;
        }
    };

    const mapStateToProps = (state) => ({
        organisations: state.entities.organisations,
    });

    const mapDispatchToProps = (dispatch) => ({
        setActiveAppSpaceId: (appSpace) => dispatch(setActiveAppSpaceId(appSpace)),
    });

    return connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(WithAuth(WithOrganisationsHoC))
}