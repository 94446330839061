import {
    jwtDecode
} from "jwt-decode";
import {
    isEmpty,
    isFull
} from "../utils";

export const EmailClaim = "email";
export const UserIdClaim = "sub";

export const decodeJwt = (jwt) => {
    try {
        return jwtDecode(jwt);
    } catch (ex) {
        return undefined;
    }
}

export const isJwtValid = (jwt) => {
    let decodedJwt = decodeJwt(jwt);
    if (isEmpty(decodedJwt)) {
        return false;
    }

    if (isFull(decodedJwt[EmailClaim]) && isFull(decodedJwt[UserIdClaim])) {
        return true;
    }

    return false;
}