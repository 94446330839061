import React from "react";
import { connect } from "react-redux";
import { setNotifications } from "../../store/reducers/appService";
import { getRandomId } from "../../common/utils";

export default function WithNotification(WrappedComponent, selectData) {
    // ...and returns another component...
    class WithNotificationHoC extends React.Component {
        notify = ({ title, message, type }) => {
            let updatedNotifications = [...this.props.notifications];

            updatedNotifications.push({
                id: getRandomId(),
                title,
                message,
                type
            });

            this.props.setNotifications(updatedNotifications);
        }

        deNotify = ({ id }) => {
            let updatedNotifications = [...this.props.notifications]
                .filter(n => n.id !== id);

            this.props.setNotifications(updatedNotifications);
        }

        render() {
            return <WrappedComponent
                notify={this.notify}
                deNotify={this.deNotify}
                {...this.props} />;
        }
    };

    const mapStateToProps = (state) => ({
        notifications: state.entities.app.notifications
    });

    const mapDispatchToProps = (dispatch) => ({
        setNotifications: (notifications) => {
            dispatch(setNotifications(notifications));
        }
    });

    return connect(mapStateToProps, mapDispatchToProps)(WithNotificationHoC)
}