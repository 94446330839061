import React, { lazy, Component } from "react";
import AppRouter from "./ApplicationRouter";
import WithAuth from "../components/Auth/WithAuth";
import PellerexNotifications from "../components/Notification/PellerexNotifications";

class Landing extends Component {
  render() {
    return (
      <div>
        <AppRouter />
        <PellerexNotifications />
      </div>
    );
  }
}

export default WithAuth(Landing);
