import { createAction } from "@reduxjs/toolkit";

export const apiCallBegan = createAction("api/CallBegan");
export const apiCallSuccess = createAction("api/CallSuccess");
export const apiCallFailed = createAction("api/CallFailed");
export const routeChangeBegan = createAction("route/ChangeBegan");
export const uiNotificationSent = createAction("uiNotification/Sent");

//Membership
export const accessTokenExpired = createAction("accountReducer/TokenExpired");
export const organisationSelected = createAction("accountReducer/organisationSelected");
export const customerSignupBegan = createAction("customer/signupBegan");
export const customerSigninBegan = createAction("customer/signinBegan");
export const customerSignoutCompleted = createAction("rootReducer/signoutCompleted");
