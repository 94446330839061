import {
    combineReducers
} from "redux";
import entitiesReducers from "./entities";
import {
    customerSignoutCompleted
} from '../store/middleware/actionUtils';
import Cookies from "js-cookie";
import {
    Auth
} from "../common/constants";
import {
    getEnvironmentVariables
} from "../common/utils";

const rootReducer = (state, action) => {
    if (action?.type === customerSignoutCompleted.type) {
        // for all keys defined in your persistConfig(s)
        //This is to reset the redux store on log out, by passing undefined to it.
        Cookies.remove(Auth.TokenHolderCookieName, {
            path: '/',
            domain: getEnvironmentVariables().REACT_APP_GLOBAL_APP_DOMAIN
        });

        return appReducer(undefined, action);
    }
    return appReducer(state, action);
};

const appReducer =
    combineReducers({
        entities: entitiesReducers,
    });

export default rootReducer;