import { createSlice } from "@reduxjs/toolkit";

export const startLoading = () => {
    return {
        type: "appReducer/loadingStarted",
        payload: {
            loading: true
        }
    };
};

export const stopLoading = () => {
    return {
        type: "appReducer/loadingStopped",
        payload: {
            loading: false
        }
    };
};

export const setNotifications = (notifications) => {
    return {
        type: "appReducer/notificationsSet",
        payload: notifications
    };
};

//
//Store Slice Creator
//

const slice = createSlice({
    name: "appReducer",
    initialState: {
        notifications: []
    },
    reducers: {
        loadingStarted: (app, action) => {
            app = {
                ...app,
                loading: true
            };

            return app;
        },
        loadingStopped: (app, action) => {
            app = {
                ...app,
                loading: false
            };

            return app;
        },
        notificationsSet: (app, action) => {
            app = {
                ...app,
                notifications: action.payload
            };

            return app;
        }
    }
});

export const {
    loadingStarted,
    loadingStopped,
    notificationsSet
} = slice.actions;

export default slice.reducer;