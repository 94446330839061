import React, { lazy } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { Routes as NewRoutes } from "../common/routing";
import { Product, Routes } from "../common/constants";
import TalkToExperts from "../screens/Website/Support/Index";
import { CompatRoute } from "react-router-dom-v5-compat";
import ContentRouter from "./ContentRouter";

//Marketplace
const Products = lazy(() => import("../screens/Marketplace/Products/Index"));
const GetStarted = lazy(() => import("../screens/Website/Onboarding/Index"));
//Services
const ManagedApiFoundationSubmission = lazy(() =>
  import(
    "../screens/Marketplace/Dashboard/App/Handlers/Service/List/ManagedApiFoundation/Components/Submission/Index"
  )
);
const ManagedApiFoundation = lazy(() =>
  import(
    "../screens/Marketplace/Products/Service/List/ManagedApiFoundation/Index"
  )
);

// > Docs
const MarketplaceDocs = lazy(() => import("../screens/Website/Docs/Index"));

// > Pages
const DefaultLanding = lazy(() =>
  import("../screens/Website/Landings/Default/Index")
);
const Policies = lazy(() =>
  import("../screens/Website/Content/Policies/Policies")
);
const Terms = lazy(() => import("../screens/Website/Content/Policies/Terms"));
const SLAs = lazy(() => import("../screens/Website/Content/Policies/SLAs"));
const RaiseTicket = lazy(() => import("../screens/Website/Support/Request"));
const NotFound = lazy(() => import("../screens/Website/NotFound"));

//User
const ProtectedRoute = lazy(() => import("../components/ProtectedRoute"));

//Content
const Acknowledge = lazy(() =>
  import("../screens/Website/Support/Acknowledge")
);
const GatedResource = lazy(() => import("../screens/Resources/Index"));
const HowPellerexWorks = lazy(() =>
  import("../screens/Website/Content/Blog/Posts/Strategy/Pellerex-Overview")
);
const BlogList = lazy(() => import("../screens/Website/Content/Blog/BlogList"));
const UrlRewrite = lazy(() =>
  import("../screens/Website/Content/Blog/Posts/API/UrlRewrite")
);
const Authentication = lazy(() =>
  import("../screens/Website/Content/Blog/Posts/Auth/Authentication")
);
const ApiSetup = lazy(() =>
  import("../screens/Website/Content/Blog/Posts/API/ApiSetup")
);
const ApiVersioning = lazy(() =>
  import("../screens/Website/Content/Blog/Posts/API/ApiVersioning")
);
const ApiConfigurationManagement = lazy(() =>
  import("../screens/Website/Content/Blog/Posts/API/ApiConfigurationManagement")
);
const ApiSecretManagement = lazy(() =>
  import("../screens/Website/Content/Blog/Posts/API/ApiSecretManagement")
);
const ApiMonitoring = lazy(() =>
  import("../screens/Website/Content/Blog/Posts/API/ApiMonitoring")
);
const ApiDatabase = lazy(() =>
  import("../screens/Website/Content/Blog/Posts/API/ApiDatabase")
);
const ApiDocumentation = lazy(() =>
  import("../screens/Website/Content/Blog/Posts/API/ApiDocumentation")
);
const ApiCors = lazy(() =>
  import("../screens/Website/Content/Blog/Posts/API/ApiCors")
);
const ApiRequestValidation = lazy(() =>
  import("../screens/Website/Content/Blog/Posts/API/ApiRequestValidation")
);
const ApiExceptionHandling = lazy(() =>
  import("../screens/Website/Content/Blog/Posts/API/ApiExceptionHandling")
);
const SpaSetup = lazy(() =>
  import("../screens/Website/Content/Blog/Posts/Web/SpaSetup")
);
const SpaStyling = lazy(() =>
  import("../screens/Website/Content/Blog/Posts/Web/SpaStyling")
);
const SpaGlobalNavigation = lazy(() =>
  import("../screens/Website/Content/Blog/Posts/Web/SpaGlobalNavigation")
);
const SpaGeneralLayout = lazy(() =>
  import("../screens/Website/Content/Blog/Posts/Web/SpaGeneralLayout")
);
const SpaForms = lazy(() =>
  import("../screens/Website/Content/Blog/Posts/Web/SpaForms")
);
const SpaRouting = lazy(() =>
  import("../screens/Website/Content/Blog/Posts/Web/SpaRouting")
);
const SpaConfiguration = lazy(() =>
  import("../screens/Website/Content/Blog/Posts/Web/SpaConfiguration")
);
const SpaApiCall = lazy(() =>
  import("../screens/Website/Content/Blog/Posts/Web/SpaApiCall")
);
const SpaMonitoring = lazy(() =>
  import("../screens/Website/Content/Blog/Posts/Web/SpaMonitoring")
);
const SpaNotification = lazy(() =>
  import("../screens/Website/Content/Blog/Posts/Web/SpaNotification")
);
const SpaReactRedux = lazy(() =>
  import("../screens/Website/Content/Blog/Posts/Web/SpaReactRedux")
);
const SpaGoogleMaps = lazy(() =>
  import("../screens/Website/Content/Blog/Posts/Web/SpaGoogleMap")
);
const SpaSeo = lazy(() =>
  import("../screens/Website/Content/Blog/Posts/Web/SpaSeo")
);
const SpaAzureDeploy = lazy(() =>
  import("../screens/Website/Content/Blog/Posts/Web/SpaAzureDeploy")
);
const SpaAspNetShellConfigurations = lazy(() =>
  import(
    "../screens/Website/Content/Blog/Posts/Web/SpaAspNetShellConfigurations"
  )
);
const AuthIdentityDbSetup = lazy(() =>
  import("../screens/Website/Content/Blog/Posts/Auth/AuthIdentitySetup")
);
const EmailSender = lazy(() =>
  import("../screens/Website/Content/Blog/Posts/Auth/EmailSender")
);
const SignUp = lazy(() =>
  import("../screens/Website/Content/Blog/Posts/Auth/SignUp")
);
const ApiCall = lazy(() =>
  import("../screens/Website/Content/Blog/Posts/API/ApiCall")
);
const AuthStatusCheck = lazy(() =>
  import("../screens/Website/Content/Blog/Posts/Auth/CheckUserStatus")
);
const AuthChangePassword = lazy(() =>
  import("../screens/Website/Content/Blog/Posts/Auth/ChangePassword")
);
const SignIn = lazy(() =>
  import("../screens/Website/Content/Blog/Posts/Auth/SignIn")
);
const TokenValidation = lazy(() =>
  import("../screens/Website/Content/Blog/Posts/Auth/TokenValidation")
);
const TokenRefresh = lazy(() =>
  import("../screens/Website/Content/Blog/Posts/Auth/TokenRefresh")
);
const PaymentBlog = lazy(() =>
  import("../screens/Website/Content/Blog/Posts/StripePayment/StripePayment")
);
const StripeCaptureCardDetails = lazy(() =>
  import("../screens/Website/Content/Blog/Posts/StripePayment/DetailsCapture")
);
const StripeOneTimePayment = lazy(() =>
  import("../screens/Website/Content/Blog/Posts/StripePayment/OneTimePayment")
);
const StripeSubscriptions = lazy(() =>
  import("../screens/Website/Content/Blog/Posts/StripePayment/Subscription")
);
const StructuredLogging = lazy(() =>
  import("../screens/Website/Content/Blog/Posts/API/ApiStructuredLogging")
);
const GoogleExternalAuthentication = lazy(() =>
  import(
    "../screens/Website/Content/Blog/Posts/Auth/GoogleExternalAuthentication"
  )
);
const MicrosoftExternalAuthentication = lazy(() =>
  import(
    "../screens/Website/Content/Blog/Posts/Auth/MicrosoftExternalAuthentication"
  )
);
const TwitterExternalAuthentication = lazy(() =>
  import(
    "../screens/Website/Content/Blog/Posts/Auth/TwitterExternalAuthentication"
  )
);
const SoftwareTransformationRoadmap = lazy(() =>
  import("../screens/Website/Content/Blog/Posts/Strategy/EngineeringRoadmap")
);
const LowCodePlatforms = lazy(() =>
  import("../screens/Website/Content/Blog/Posts/Strategy/LowCodeComparison")
);
const AspNetApiContainerisation = lazy(() =>
  import(
    "../screens/Website/Content/Blog/Posts/Infrastructure/ApiContainerisation"
  )
);
const AspNetAppContainerisation = lazy(() =>
  import(
    "../screens/Website/Content/Blog/Posts/Infrastructure/AppContainerisation"
  )
);
const BoilerplateSolutions = lazy(() =>
  import("../screens/Website/Content/Blog/Posts/Strategy/BoilerplateSolutions")
);
const DockerCompose = lazy(() =>
  import("../screens/Website/Content/Blog/Posts/Infrastructure/DockerCompose")
);
const PellerexOnMinikube = lazy(() =>
  import(
    "../screens/Website/Content/Blog/Posts/Infrastructure/PellerexOnMinikube"
  )
);
const PellerexOnKubernetes = lazy(() =>
  import(
    "../screens/Website/Content/Blog/Posts/Infrastructure/PellerexOnProdKube"
  )
);
const EventDrivenArchitecture = lazy(() =>
  import(
    "../screens/Website/Content/Blog/Posts/Messaging/EventDrivenArchitecture"
  )
);
const CommandsRepo = lazy(() =>
  import("../screens/Website/Content/Blog/Posts/Common/Commands")
);
const PerformanceUplift = lazy(() =>
  import(
    "../screens/Website/Content/Blog/Posts/Infrastructure/PerformanceUplift"
  )
);
const RealtimeMessaging = lazy(() =>
  import("../screens/Website/Content/Blog/Posts/Messaging/RealtimeChat")
);
const AzureDevOpsRestAPIs = lazy(() =>
  import("../screens/Website/Content/Blog/Posts/DevOps/AzureDevOps/Index")
);
const AzureDevOpsCreateProject = lazy(() =>
  import(
    "../screens/Website/Content/Blog/Posts/DevOps/AzureDevOps/CreateProject"
  )
);
const AzureDevOpsFetchProjects = lazy(() =>
  import(
    "../screens/Website/Content/Blog/Posts/DevOps/AzureDevOps/FetchProjects"
  )
);
const AzureDevOpsInviteUserToOrganisation = lazy(() =>
  import(
    "../screens/Website/Content/Blog/Posts/DevOps/AzureDevOps/InviteUserToOrganisation"
  )
);
const AzureDevOpsCreateSecurityGroups = lazy(() =>
  import(
    "../screens/Website/Content/Blog/Posts/DevOps/AzureDevOps/CreateSecurityGroup"
  )
);

const OutOfTheBoxLoggingByPellerex = lazy(() =>
  import(
    "../screens/Website/Content/Blog/Posts/Announcements/CentralisedLogging"
  )
);
const PellerexSecretManagement = lazy(() =>
  import("../screens/Website/Content/Blog/Posts/Announcements/SecretManagement")
);
const LatestNews = lazy(() =>
  import("../screens/Website/Content/Latest/LatestNews")
);
const Prices = lazy(() =>
  import("../screens/Website/Conversion/Pricing/ManagedApiPricing")
);

const PlatformEngineering = lazy(() =>
  import("../screens/Website/Content/Blog/Posts/PlatformEngineering/WhatIsPlatformEngineering")
);

const AuthContainer = lazy(() => import("../screens/User/Auth/AuthContainer"));

const PortalRouter = (props) => {
  return (
    <Switch>
      <Route exact path={Routes.Marketplace.Pages.Landings.Default}>
        <DefaultLanding />
      </Route>

      {/* User */}
      <CompatRoute
        exact
        path={NewRoutes.Application.Identity.Login}
        component={AuthContainer}
      />

      {/*Content*/}
      <ProtectedRoute
        exact
        path={Routes.Marketplace.Pages.Conversion.PlatformSupport}
        component={RaiseTicket}
      />

      {/* Conversion */}
      <Route
        exact
        path={Routes.Marketplace.Pages.Conversion.TalkToExpert}
        component={TalkToExperts}
      />
      <Route
        exact
        path={NewRoutes.Website.Pages.Conversion.Prices}
        component={Prices}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Conversion.Demo}
        component={TalkToExperts}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Conversion.Acknowledge}
        component={Acknowledge}
      />
      <Route exact path="/resource/:name?" component={GatedResource} />

      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.Directory}
        component={BlogList}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.WhatIsPellerex}
        component={HowPellerexWorks}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.URLRewrite}
        component={UrlRewrite}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.Authentication}
        component={Authentication}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.AspNetCoreSetup}
        component={ApiSetup}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.ApiVersioning}
        component={ApiVersioning}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.ApiConfigManagement}
        component={ApiConfigurationManagement}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.ApiSecretManagement}
        component={ApiSecretManagement}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.ApiMonitoring}
        component={ApiMonitoring}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.ApiDatabaseSetup}
        component={ApiDatabase}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.ApiDocumentation}
        component={ApiDocumentation}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.ApiCors}
        component={ApiCors}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.ApiFluentValidation}
        component={ApiRequestValidation}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.ApiExceptionHandling}
        component={ApiExceptionHandling}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.AppSetup}
        component={SpaSetup}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.AppThemeManagement}
        component={SpaStyling}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.AppGlobalNavigation}
        component={SpaGlobalNavigation}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.AppLayout}
        component={SpaGeneralLayout}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.AppForms}
        component={SpaForms}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.AppRouting}
        component={SpaRouting}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.AppConfiguration}
        component={SpaConfiguration}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.AppApiCall}
        component={SpaApiCall}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.AppMonitoring}
        component={SpaMonitoring}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.AppNotification}
        component={SpaNotification}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.AppRedux}
        component={SpaReactRedux}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.AppGoogleMaps}
        component={SpaGoogleMaps}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.AppSEO}
        component={SpaSeo}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.AppShellConfiguration}
        component={SpaAspNetShellConfigurations}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.AppAzureDeploy}
        component={SpaAzureDeploy}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.ApiIdentityDbSetup}
        component={AuthIdentityDbSetup}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.ApiIdentityEmailSender}
        component={EmailSender}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.IdentitySignUp}
        component={SignUp}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.ApiCallOtherApis}
        component={ApiCall}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.AppAuthStatusCheck}
        component={AuthStatusCheck}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.IdentityChangePassword}
        component={AuthChangePassword}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.IdentitySignIn}
        component={SignIn}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.IdentityClaimBasedAccessManagement}
        component={TokenValidation}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.IdentityTokenRefresh}
        component={TokenRefresh}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.PaymentWithStripe}
        component={PaymentBlog}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.PaymentCaptureCardDetails}
        component={StripeCaptureCardDetails}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.PaymentOneTimeWithStripe}
        component={StripeOneTimePayment}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.PaymentSubscriptionsWithStripe}
        component={StripeSubscriptions}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.ApiLoggingWithSeriLog}
        component={StructuredLogging}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.IdentityGoogleAuth}
        component={GoogleExternalAuthentication}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.IdentityMicrosoftAuth}
        component={MicrosoftExternalAuthentication}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.IdentityTwitterAuth}
        component={TwitterExternalAuthentication}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.SoftwareRoadmap}
        component={SoftwareTransformationRoadmap}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.TopLowCodePlatforms}
        component={LowCodePlatforms}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.ApiContainerisation}
        component={AspNetApiContainerisation}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.AppContainerisation}
        component={AspNetAppContainerisation}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.BoilerPlateSolutions}
        component={BoilerplateSolutions}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.DockerComposeMultiContainerApps}
        component={DockerCompose}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.MinikubeSetup}
        component={PellerexOnMinikube}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.KubernetesSetup}
        component={PellerexOnKubernetes}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.EventDrivenArchitecture}
        component={EventDrivenArchitecture}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.CommandsCheatSheet}
        component={CommandsRepo}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.PerformanceAndCostOptimisation}
        component={PerformanceUplift}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.ChatUsingSignalR}
        component={RealtimeMessaging}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.AzureDevOpsRestAPIs}
        component={AzureDevOpsRestAPIs}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.AzureDevOpsCreateProject}
        component={AzureDevOpsCreateProject}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.AzureDevOpsFetchProjects}
        component={AzureDevOpsFetchProjects}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.AzureDevOpsInviteUsers}
        component={AzureDevOpsInviteUserToOrganisation}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.AzureDevOpsCreateSecurityGroup}
        component={AzureDevOpsCreateSecurityGroups}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.LoggingWithPellerex}
        component={OutOfTheBoxLoggingByPellerex}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.PellerexSecretManagement}
        component={PellerexSecretManagement}
      />
      <Route
        exact
        path={Routes.Marketplace.Pages.Blogs.PlatformEngineering}
        component={PlatformEngineering}
      />
      <Route
        exact
        path={NewRoutes.Website.Pages.Latest.Home}
        component={LatestNews}
      />

      <Route exact path={Routes.Marketplace.Pages.Products.Index}>
        <Products />
      </Route>

      <Route exact path={Routes.Marketplace.Pages.Products.APIs}>
        <Products category={Product.Category.API} />
      </Route>

      <Route exact path={Routes.Marketplace.Pages.Products.Service.List.All}>
        <Products category={Product.Category.Service} />
      </Route>

      <Route exact path={Routes.Marketplace.Pages.Products.Foundation.List}>
        <Products category={Product.Category.Foundation} />
      </Route>

      {/* Foundations */}
      <Route
        exact
        path={Routes.Marketplace.Pages.Products.Foundation.GetStarted}
        component={GetStarted}
      />

      {/* Services */}
      <Route
        exact
        path={`${Routes.Marketplace.Pages.Products.Service.Base}/${Product.WellKnown.ManagedApi.NormalisedName}`}
        component={ManagedApiFoundation}
      />
      <ProtectedRoute
        exact
        path={
          Routes.Marketplace.Pages.Products.Service.List.ManagedApiFoundation
            .Submission
        }
        component={ManagedApiFoundationSubmission}
      />

      {/* Documentation */}
      <Route
        exact
        path={`${NewRoutes.Website.Pages.Docs.Base}/:route?`}
        component={MarketplaceDocs}
      />

      {/*Others*/}
      <Route exact path="/policies" component={Policies} />
      <Route exact path="/terms" component={Terms} />
      <Route exact path="/slas" component={SLAs} />

      <ContentRouter />
      <Route component={NotFound} />

    </Switch>
  );
};

export default withRouter(PortalRouter);
