import querystring from "query-string";
import ApplicationLanding from "../containers/ApplicationLanding";
import WebsiteLanding from "../containers/WebsiteLanding";
import { Application } from "./constants";
import { getEnvironmentVariables, isEmpty } from "./utils";

export const readQueryString = (location, name) => {
  let params = querystring.parse(location.search);
  return params[name];
};

export const Apps = [
  {
    subDomain: "app",
    appName: Application.Application,
    app: ApplicationLanding,
    main: false,
  },
  {
    subDomain: "",
    appName: Application.Website,
    app: WebsiteLanding,
    main: true,
  },
];

export const getAppLanding = () => {
  const mainApp = Apps.find((app) => app.main);

  if (!mainApp) {
    throw new Error("Must have main app");
  }

  let subDomain = getSubdomain(window.location.hostname);

  if (subDomain === "") {
    return mainApp.app;
  }

  const app = Apps.find((app) => app.subDomain === subDomain);

  if (!app) {
    return mainApp.app;
  }

  return app.app;
};

export const getSubdomain = (url) => {
  var regexParse = new RegExp("[a-z-0-9]{2,63}.[a-z.]{2,5}$");
  var urlParts = regexParse.exec(url);
  return url.replace(urlParts[0], "").slice(0, -1);
};

export const getAbsolutePath = ({ appName, path }) => {
  for (let i = 0; i < Apps.length; i++) {
    let app = Apps[i];
    if (app.appName === appName) {
      return getFQDN({
        subDomain: app.subDomain,
        path,
      });
    }
  }

  let mainApp = Apps.find((app) => app.main);
  return getFQDN({
    subDomain: mainApp.subDomain,
    path,
  });
};

export const getFQDN = ({
  subDomain,
  domain = `${getEnvironmentVariables().REACT_APP_HOST_ADDRESS}`,
  path,
}) => {
  if (isEmpty(subDomain)) {
    return `https://${domain}${path}`;
  }
  return `https://${subDomain}.${domain}${path}`;
};

export const Routes = {
  Website: {
    Home: "/",
    Terms: "/terms",
    Policies: "/policies",
    Pages: {
      Blogs: {
        Directory: "/blog/list",
      },
      Docs: {
        Base: "/docs",
        UrlTemplate: "/docs?id={docId}",
        Platform: {
          Tenancy: "pellerex-tenancy",
          Organisation: "pellerex-organisations",
          AppSpaces: "pellerex-appspaces",
        },
        ManagedApiService: {
          GettingStarted: "managed-api-service-getting-started",
          Scaffolding: "managed-api-services-scaffolding",
          DevOps: "managed-api-services-devops",
          SecretManagement: "managed-api-service-secret-management",
          Database: "managed-api-service-databases",
          Infrastructure: "managed-api-service-infrastructure",
          Consumption: "managed-api-service-consumption",
          Monitoring: "managed-api-service-monitoring",
          Compute: "managed-api-services-compute",
          Usage: "managed-api-service-usage",
        },
        ManagedIdentity: {
          AcquireJwt: "managed-identity-acquire-jwt",
        },
        Plans: {
          Subscribe: "plans-subscribe",
          PluginModel: "plans-plugin-model",
          Unsubscribe: "plans-unsubscribe",
        },
      },
      Latest: {
        Home: "/latest",
      },
      Conversion: {
        TalkToExpert: "/contact/expert",
        Prices: "/prices",
        Content: {
          Solutions: {
            IDP: "/solutions/idp",
          },
        },
      },
      Others: {
        Terms: "/terms",
        Policies: "/policies",
      },
    },
  },
  Application: {
    Identity: {
      Login: "/auth",
      Organisation: {
        Selection: "/organisations/selection",
      },
      Social: {
        AuthenticationUrlTemplate:
          "{baseUrl}?provider={provider}&returnUrl={returnUrl}&tenantId={tenantId}&organisationId={organisationId}&embeddedMode={embeddedMode}&audience={audience}&scopes={scopes}",
        ExternalLoginCallbackUrl:
          "/auth/external-login-callback-pellerex-frontend",
      },
      ChangePassword: "/change-password",
      RequestPasswordResetLink: "/request-password-reset-link",
      RequestEmailActivationLink: "/request-email-activation-link",
    },
    Contributors: {
      Directory: "/contributors",
      Profile: "/c",
      Pricing: "/company/pricing",
    },
    Dashboard: {
      Default: "/",
      Home: "/dashboard",
      DefaultLanding: "/dashboard/home",
      Organisation: "/dashboard/organisation",
      Apps: "/dashboard/apps",
      AppSpace: {
        Selection: "/app-space/selection",
      },
      Support: "/dashboard/support",
      Products: "/dashboard/products",
      Inbox: "/dashboard/inbox",
      Profile: "/dashboard/profile",
      BillingDetails: "/dashboard/billing-details",
    },
  },
};
