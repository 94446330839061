import React from 'react';
import {
    InlineWidget
} from "react-calendly";

export const Calendar = (props) => {
    return ( <
        InlineWidget styles = {
            {
                'min-width': '320px',
                'height': '1000px'
            }
        }
        url = {
            `https://calendly.com/${props.eventId}?hide_landing_page_details=1&hide_gdpr_banner=1`
        }
        />
    )
}